import React from "react"
import PropTypes from "prop-types"
import "../assets/sass/core/all.scss"
import "../assets/sass/theme/settings/_all.scss"

const Layout = ({ children }) => {
  return (
    <>
      <main id="maincontent" className="main">{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
