import React from "react";
import { graphql } from 'gatsby';
import Layout from "../components/layout";

class IndexPage extends React.Component {

  render() {
    const { data } = this.props;
    const pages = data.allSitePage.edges;
    return (
      <Layout>
        <ul>
          {pages &&
            pages.map((page) => (
                <li key={page.node.id}>
                  <a href={page.node.path}>
                    {page.node.path}
                  </a>
                </li>
            ))}
        </ul>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    allSitePage {
      edges {
        node {
          id
          path
        }
      }
    }
  }
`
